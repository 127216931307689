// .env.firebase.ts
const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBZ3ytttB4hmg-dFPwFjhzVPRcFNNxJmks",
    authDomain: "myfiapp.firebaseapp.com",
    databaseURL: "https://myfiapp.firebaseio.com",
    projectId: "myfiapp",
    storageBucket: "myfiapp.appspot.com",
    messagingSenderId: "681654305112",
    appId: "1:681654305112:web:badbb312bf8c36b0fba52d",
    measurementId: "G-D53ZQ0JDX2"
  };
  
  export default FIREBASE_CONFIG 