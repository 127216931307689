import { toRefs, reactive } from "vue";
import firebase from "firebase/app";
import "firebase/auth";
// Required for side-effects
import "firebase/firestore";

import FIREBASE_CONFIG from "./.env.firebase";

// initialize firebase, this is directly from the firebase documentation
// regarding getting started for the web
if (firebase.apps.length === 0) {
  firebase.initializeApp(FIREBASE_CONFIG);
}

// const USERS_COLLECTION_PATH = "users/";

type TState = {
  error: Error | null;
  loading: boolean;
  user: firebase.auth.UserCredential | null | firebase.User;
  userData: any;
  initialized: boolean;
};

const state = reactive<TState>({
  user: null,
  loading: true,
  error: null,
  userData: null,
  initialized: false,
});

export default function() {
  const getUserData = async () => {
    const uid: any = firebase.auth().currentUser?.uid
    // console.log('getUserData ' + uid)
    const resp  = await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get();

      if (resp.exists) {
        // console.log(resp.data())
        const result = {
          ...resp.data(),
          uid : firebase.auth().currentUser?.uid
        }
        state.userData = result
        localStorage.setItem('userData', JSON.stringify(result))
        return result
      }
  };

  /**
   *
   * @param username
   * @param password
   */
  const login = (username: string, password: string) => {
    state.loading = true;
    return firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(
        async (user) => {
          console.log(user)
          state.error = null;
          state.loading = false;
          state.user = user;
          await getUserData();
          return user;
        },
        (error) => {
          state.error = error;
          state.loading = false;
          throw error;
        }
      )
      .catch((error) => {
        // alert('here')
          
        // Handle Errors here.
        state.error = error;
        state.loading = false;
        throw error;
      });
  };

  /**
   *
   */
  const logout = () => {
    return firebase
      .auth()
      .signOut()
      .then(
        () => {
          state.error = null;
          state.loading = false;
          state.user = null;
          state.userData = null;
          localStorage.removeItem('userData')
          localStorage.removeItem('bookLists')
          localStorage.removeItem('selectedBook')
        },
        (error) => {
          state.error = error;
          state.loading = false;
          throw error;
        }
      )
      .catch((error) => {
        // Handle Errors here.
        state.error = error;
        state.loading = false;
        throw error
      });
  };

  const doEmailVerification = () => {
    return firebase
      .auth()
      .currentUser?.sendEmailVerification()
      .then(() => {
        const error = {
          'header': 'Email Verification',
          'message': 'Please Check your Email for Verification Link'
        }
        throw error;
      })
      .catch((error) => {
        // Handle Errors here.
        state.error = error;
        state.loading = false;
        throw error;
      });
  };

  /**
   *
   */
  const register = (username: string, password: string) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(username, password)
      .then(
        async () => {
          state.error = null;
          state.loading = false;
          const uid: any = firebase.auth().currentUser?.uid
          await firebase
            .firestore()
            .collection('users')
            .doc(uid)
            .set({
              accountType: 1,
              name: firebase.auth().currentUser?.displayName,
              email: firebase.auth().currentUser?.email,
              birthDate: null,
              mobile: null,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              settings: {
                defaultBook: ""
              }
            })        
          const verifyEmail = doEmailVerification()
          throw verifyEmail
          // const error = {
          //   'header': 'Email Verification',
          //   'message': 'Please Check your Email for Verification Link'
          // }
          // state.user = user;
          // state.userData = await getUserData();
          // return user;
        },
        (error) => {
          state.error = error;
          state.loading = false;
          throw error;
        }
      )
      .catch((error) => {
        // Handle Errors here.
        state.error = error;
        state.loading = false;
        throw error;
      });
  };

  // RUN AT STARTUP
  const authCheck = () => {
    return new Promise((resolve) => {
      state.loading = true;
      !state.initialized &&
        firebase.auth().onAuthStateChanged(async (_user) => {
          if (_user) {
            state.user = _user;
            state.userData = await getUserData();
          } else {
            state.user = null;
          }
          state.loading = false;
          state.initialized = true;
          resolve(true);
        });
    });
  };

  const updateProfile = async (params: any) => {
    console.log(params)
    await firebase
      .firestore()
      .collection('users')
      .doc(params.uid)
      .update({
        name: params.name,
        birthDate: params.birthDate,
        mobile: params.mobile,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  }

  return {
    ...toRefs(state),
    // FUNCTIONS
    login,
    logout,
    register,
    authCheck,
    getUserData,
    updateProfile
  };
}